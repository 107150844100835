<template>
  <div class="materials">
    <div class="addbtn">
      <!-- <el-button
        type="primary"
        @click="addMaterials"
      >
        新增
      </el-button> -->
      <!-- 按条件筛选查找数据 -->
      <el-form
        ref="form"
        :inline="true"
        :model="formInline"
        small="size"
        label-width="130px"
      >
        <el-form-item>
          <el-input
            v-model.trim="formInline.name"
            v-check-permission
            placeholder="请输入物料名称或ID"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getMaterialList"> 查询 </el-button>
          <el-button type="primary" @click="getMaterialListClear">
            重置
          </el-button>
          <el-dropdown style="marginleft: 10px" @command="addMaterials">
            <el-button type="primary">
              新增<i class="el-icon-arrow-down el-icon--right" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="typeMap.H5_LIST">
                医生列表(h5)
              </el-dropdown-item>
              <el-dropdown-item :command="typeMap.LIST">
                医生报道列表(小程序)
              </el-dropdown-item>
              <el-dropdown-item :command="typeMap.DETAIL">
                医生报道首页(小程序)
              </el-dropdown-item>

              <el-dropdown-item :command="typeMap.MIN_LIST">
                医生列表(小程序)
              </el-dropdown-item>

              <el-dropdown-item :command="typeMap.MIN_DETAIL">
                医生首页(小程序)
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
      </el-form>
    </div>

    <!-- 列表表格 -->
    <template>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="序号" type="index" width="50" />
        <el-table-column label="物料ID" prop="id" align="center" />
        <el-table-column
          label="物料名称"
          prop="materialName"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column label="物料类型" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.materialType | materialTypeFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="渠道"
          prop="materialChannelName"
          align="center"
          show-overflow-tooltip
        >
          <!-- <template slot-scope="scope"> -->
          <!-- <span>{{ scope.row.materialType | materialTypeFilter }}</span> -->
          <!-- </template> -->
        </el-table-column>

        <el-table-column
          label="物料生成时间"
          prop="createTime"
          align="center"
        />
        <el-table-column fixed="right" label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.native.prevent="viewDetail(scope.row.id)"
            >
              查看
            </el-button>
            <el-button
              :disabled="
                scope.row.materialChannelCode === 'doctor_small' ? true : false
              "
              type="text"
              size="small"
              @click.native.prevent="modifyDetail(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="$checkPermission('DOWNLOAD', $route)"
              type="text"
              size="small"
              @click.native.prevent="downLoad(scope.row.id)"
            >
              下载
            </el-button>
            <el-button
              type="text"
              size="small"
              @click.native.prevent="statisDetail(scope.row.id)"
            >
              统计
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <!-- 新增、编辑 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="50%">
      <el-form
        v-if="dialogVisible"
        label-position="right"
        :model="form"
        inline
        label-width="120px"
        @submit.native.prevent
      >
        <el-form-item>
          <el-input
            v-model="form.doctorName"
            v-check-permission
            placeholder="请输入医生姓名"
            style="width: 80%"
            maxlength="20"
            @keyup.enter.native="saveProfessional()"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData"> 查询 </el-button>
          <el-button type="primary" @click="resetData"> 重置 </el-button>
          <el-button
            v-show="
              addPopupModal === typeMap.LIST ||
              addPopupModal === typeMap.H5_LIST ||
              addPopupModal === typeMap.MIN_LIST
            "
            type="primary"
            @click="batchAdd"
          >
            批量添加
          </el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="multipleTable"
        :data="tableDoctorData"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="
            addPopupModal === typeMap.LIST ||
            addPopupModal === typeMap.H5_LIST ||
            addPopupModal === typeMap.MIN_LIST
          "
          type="selection"
          width="55"
        />
        <el-table-column label="序号" type="index" width="50" />
        <el-table-column label="机构名称" prop="orgName" align="center" />
        <el-table-column label="医生姓名" prop="doctorName" align="center" />
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.native.prevent="addDoctor(scope.row)"
            >
              添加
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo1.page"
        :page-size="pageInfo1.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo1.total"
        @size-change="handleSizeChange1"
        @current-change="handleCurrentChange1"
      />
      <div class="materialsInfo">
        <el-form ref="form1" :model="form1" :rules="rules" label-width="120px">
          <div class="doctorNameList">
            <el-tag
              v-for="(item, index) in doctorNameList"
              :key="item.id"
              class="doctorName"
              type="info"
            >
              {{ item.doctorName }}
              <i class="el-icon-close" @click="deleteDoctorName(index)" />
            </el-tag>
          </div>
          <el-form-item
            label="物料名称："
            prop="materialName"
            style="width: 80%"
          >
            <el-input
              v-model="form1.materialName"
              v-check-permission
              placeholder="请输入物料名称"
              maxlength="50"
            />
          </el-form-item>
          <el-form-item
            label="渠道名称："
            prop="materialChannelCode"
            style="width: 80%"
          >
            <!-- 物料类型列表 -->
            <el-select
              v-model="materialValue"
              placeholder="请选择"
              style="width: 100%"
              @change="getCode"
            >
              <el-option
                v-for="item in materialChannelNameList"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="generateQRcode">生成二维码</el-button>
      </span>
    </el-dialog>

    <!-- 二维码查看弹窗 -->
    <el-dialog
      title="心管家二维码"
      :visible.sync="showDetail"
      width="25%"
      v-loading="loading"
      center
    >
      <div class="info">
        <div id="qrcode" ref="qrCodeUrl" class="qrcode" />
        <div class="doctorInfo">
          <label class="doctorInfo-label">医生姓名:</label>
          {{ materialDoctorName }}
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showDetail = false">关闭</el-button>
      </span>
    </el-dialog>
    <a id="downloadLink" />
    <div id="qrcode1" ref="qrCodeUrl1" class="qrcode1" />
  </div>
</template>
<script>
import html2canvas from 'html2canvas';
import QRCode from 'qrcodejs2';
import { mapActions } from 'vuex';

export default {
  name: 'Materials',
  filters: {
    materialTypeFilter(str) {
      if (str === 'H5_LIST') return '医生列表(h5)';
      if (str === 'LIST') return '医生报道列表(小程序)';
      if (str === 'DETAIL') return '医生报道首页(小程序)';
      if (str === 'MIN_LIST') return '医生列表(小程序)';
      if (str === 'MIN_DETAIL') return '医生首页(小程序)';

      return str;
    },
  },
  data() {
    this.typeMap = {
      LIST: 'LIST',
      DETAIL: 'DETAIL',
      H5_LIST: 'H5_LIST',
      MIN_LIST: 'MIN_LIST',
      MIN_DETAIL: 'MIN_DETAIL',
    };
    return {
      loading: false,
      tableData: [], // 物料列表
      tableDoctorData: [], // 医生列表
      addPopupModal: '', // 新增弹窗模式 LIST-医生列表 DETAIL-医生首页
      // 医生列表查询条件
      form: {
        doctorName: '',
      },
      // 物料列表查询条件
      formInline: {
        name: '',
      },
      // 物料名称
      form1: {
        id: '', // 用于标识新增和修改，在修改时回传接口
        materialName: '',
        materialChannelCode: '',
      },
      rules: {
        materialName: [
          { required: true, message: '请输入物料名称', trigger: 'blur' },
        ],
        // materialChannelName:[
        //   {required :true, message:'请输入渠道名称', trigger:'blur'}
        // ]
      },
      pageInfo: {
        // 物料分页查询分页参数
        page: 1,
        size: 10,
        total: 0,
      },
      pageInfo1: {
        page: 1,
        size: 10,
        total: 0,
      },
      dialogVisible: false,
      doctorNameList: [], // 医生名字列表
      selectData: [], // 选中的数据
      showDetail: false, // 物料详情弹框
      materialInfo: [],
      materialChannelNameList: [], // 渠道类型列表
      materialValue: '',
      qudaoCode: '', // 渠道code
    };
  },
  computed: {
    isHaveMaterial() {
      const {
        pageInfo: { total },
      } = this;
      if (total > 0) {
        return true;
      }
      return false;
    },
    materialDoctorName() {
      const { materialInfo } = this;
      const list = [];
      if (materialInfo.length > 0) {
        materialInfo.forEach((v) => {
          list.push(v.doctorName);
        });
        return list.join('、');
      }
      return '';
    },
  },
  mounted() {
    this.getMaterialList();
  },
  methods: {
    // 物料渠道类型列表
    ...mapActions('basicDictionary', ['dicQueryList']),
    // 获取物料渠道编码
    getCode(code) {
      this.qudaoCode = code;
      console.log('显示这个code', code);
    },
    // 物料分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getMaterialList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getMaterialList();
    },
    // 医生分页方法调用
    handleSizeChange1(currentPage) {
      this.pageInfo1.size = currentPage;
      this.getDoctorList();
    },
    handleCurrentChange1(currentPage) {
      this.pageInfo1.page = currentPage;
      this.getDoctorList();
    },
    // 新增物料按钮
    addMaterials(type) {
      console.log('新增类型：', type);
      this.addPopupModal = type;
      this.form.doctorName = '';
      this.dialogVisible = true;
      this.doctorNameList = [];
      this.form1 = {
        id: '',
        materialName: '',
      };
      this.getDoctorList();
      this.dicQueryList({ dicTypeCode: 'Material_Channel_01' }).then((res) => {
        this.materialChannelNameList = res.data;
        // .map((item) => {
        //   return item.name;
        // });
        console.log(
          '打印这个materialChannelNameList--渠道列表',
          this.materialChannelNameList
        );
      });
    },
    // 获取物料详情
    async getMaterialDetail(id) {
      // this.loading = true;
      const res = await this.$api.materialDetail({ id: id });
      console.log('查询物料详情：', res);
      const { data } = res;
      this.materialInfo = data.doctors;
      this.creatQrCode(data);
      // this.loading = false;
    },
    // 跳转到统计详情页
    statisDetail(id) {
      this.$router.push({
        path: '/doctorManage/statis',
        query: {
          id: id,
        },
      });
    },
    // 查看二维码
    viewDetail(id) {
      this.showDetail = true;
      this.getMaterialDetail(id);
    },
    // 物料列表 编辑按钮
    async modifyDetail(item) {
      console.log('编辑：', item);
      const { code, data } = await this.$api.materialDetail({ id: item.id });
      console.log('查询物料详情：', data);
      if (code !== 200) return;
      // 构造回显数据
      this.doctorNameList = data.doctors.map((v) => {
        return {
          id: v.doctorId,
          doctorName: v.doctorName,
        };
      });

      this.form1 = {
        id: item.id,
        materialName: item.materialName,
        // materialChannelCode:item.materialChannelCode
      };
      this.addPopupModal = item.materialType;
      this.form.doctorName = '';
      this.dialogVisible = true;

      this.dicQueryList({ dicTypeCode: 'Material_Channel_01' }).then((res) => {
        this.materialChannelNameList = res.data;
        // .map((item) => {
        //   return item.name;
        // });
        console.log(
          '打印这个materialChannelNameList--渠道列表',
          this.materialChannelNameList
        );
      });
      this.materialValue = item.materialChannelName;
      this.getDoctorList();
    },
    downLoad(id) {
      this.getDownloadCode(id);
    },
    // 下载二维码
    async getDownloadCode(id) {
      const res = await this.$api.materialDetail({ id: id });
      console.log('查询物料详情：', res);
      const { data } = res;

      this.createCode(data);
      // this.materialInfo = data.doctors;
      // this.creatQrCode(data);

      // this.$api.getMaterialQrCodeUrl({ id: id }).then((res) => {
      //   this.createCode(res.data);
      // });
    },
    // 查询
    queryData() {
      this.getDoctorList();
    },
    // 重置
    resetData() {
      this.form = {
        doctorName: '',
      };
      this.getDoctorList();
    },
    // 物料弹窗添加医生
    addDoctor(data) {
      console.log('添加医生：', this.doctorNameList);
      if (
        this.addPopupModal === this.typeMap.LIST ||
        this.addPopupModal === this.typeMap.H5_LIST ||
        this.addPopupModal === this.typeMap.MIN_LIST
      ) {
        if (this.doctorNameList.every((v) => v.id !== data.id)) {
          this.doctorNameList.push({
            id: data.id,
            doctorName: data.doctorName,
          });
        }
      } else {
        this.doctorNameList = [
          {
            id: data.id,
            doctorName: data.doctorName,
          },
        ];
      }
    },
    // 选择数据
    handleSelectionChange(val) {
      const list = [];
      val.forEach((v) => {
        const obj = {
          id: v.id,
          doctorName: v.doctorName,
        };
        list.push(obj);
      });
      this.selectData = list;
    },
    // 批量添加
    batchAdd() {
      this.doctorNameList = [];
      this.doctorNameList = JSON.parse(JSON.stringify(this.selectData));
      this.$refs.multipleTable.clearSelection();
    },
    // 删除选中的医生
    deleteDoctorName(index) {
      this.doctorNameList.splice(index, 1);
    },
    // 重置
    getMaterialListClear() {
      // 查询条件初始化
      this.formInline.name = '';
      // 分页功能参数初始化
      this.pageInfo = {
        page: 1,
        size: 10,
        total: 0,
      };
      this.getMaterialList();
    },
    // 物料分页查询
    getMaterialList() {
      const { pageInfo } = this;
      const param = {
        materialName: this.formInline.name,
        page: {
          done: true,
          pageIndex: pageInfo.page,
          pageSize: pageInfo.size,
        },
      };
      this.$api.materialPageQuery(param).then((res) => {
        console.log('查询物料列表：', JSON.parse(JSON.stringify(res)));
        const { data } = res;
        this.pageInfo.total = data.total;
        this.tableData = data.data;
      });
    },
    // 复诊医生列表查询
    getDoctorList() {
      const { form, pageInfo1 } = this;
      const param = {
        doctorName: form.doctorName,
        page: {
          done: true,
          pageIndex: pageInfo1.page,
          pageSize: pageInfo1.size,
        },
      };
      this.$api.furtherConsultationDoctorList(param).then((res) => {
        const { data } = res;
        this.pageInfo1.total = data.total;
        this.tableDoctorData = data.data;
      });
    },
    // 物料弹窗 生成二维码按钮
    generateQRcode() {
      const { doctorNameList, form1 } = this;
      /* if(doctorNameList.length === 0) {
          this.$message.error('医生不能为空');
          return;
        } */
      this.$refs['form1'].validate((valid) => {
        if (valid) {
          const param = {
            doctorIds: doctorNameList.map((v) => v.id),
            materialName: form1.materialName,
            materialType: this.addPopupModal,
            materialChannelCode: this.qudaoCode,
          };
          console.log('打印这个参数params:', param);
          let apiMethod = 'materialAdd';

          // form1表单中有id说明是编辑模式
          if (this.form1.id) {
            param['id'] = this.form1.id;
            apiMethod = 'materialEdit';
          }
          this.$api[apiMethod](param).then((res) => {
            if (res.code === 0) {
              this.doctorNameList = [];
              this.form1.materialName = '';
              this.form.doctorName = '';
              this.dialogVisible = false;
              this.$message.success('操作成功');
              this.getMaterialList();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 下载二维码构造
    createCode(data) {
      document.getElementById('qrcode1').innerHTML = '';
      let url = `${data.materialUrl}?materialId=${data.materialId}&materialType=${data.materialType}`;

      if (data.materialType == 'MIN_DETAIL') {
        url = `${url}&doctorcode=${data.doctors[0]['doctorId']}`;
      }
      new QRCode(this.$refs.qrCodeUrl1, {
        text: url, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      });
      this.downloadClick();
    },
    // 二维码下载
    downloadClick() {
      html2canvas(this.$refs.qrCodeUrl1, {
        backgroundColor: null,
        width: 200,
        height: 200,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        var downloadLink = document.getElementById('downloadLink');
        downloadLink.setAttribute('href', imgData);
        downloadLink.setAttribute('download', '二维码.png');
        downloadLink.click();
      });
    },
    // 二维码
    async creatQrCode(data) {
      document.getElementById('qrcode').innerHTML = '';

      let url = `${data.materialUrl}?materialId=${data.materialId}&materialType=${data.materialType}`;

      if (data.materialType == 'MIN_DETAIL') {
        url = `${url}&doctorcode=${data.doctors[0]['doctorId']}`;
      }

      console.log(url, 'urlurlurlurlurlurl');
      new QRCode(this.$refs.qrCodeUrl, {
        text: url, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.materials {
  padding: 10px;

  .addbtn {
    margin-bottom: 10px;
  }
}

body .el-table th.gutter {
  display: table-cell !important;
}

.materialsInfo {
  margin-top: 72px;
}

.doctorNameList {
  display: flex;
  padding: 20px;
  // margin-bottom: 20px;
  border-top: 1px solid #e6ebf5;
  flex-wrap: wrap;

  .doctorName {
    margin-right: 20px;
  }
}

.qrcode {
  display: inline-block;

  img {
    width: 132px;
    height: 132px;
    padding: 6px; // 利用padding的特性，挤出白边
    background-color: #fff; //设置白色背景色
    box-sizing: border-box;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;

  .doctorInfo {
    display: flex;
    margin-top: 20px;

    &-label {
      padding-right: 10px;
      font-size: 14px;
      font-weight: bold;
      color: #606266;
    }
  }
}

.qrcode1 {
  position: relative;
  z-index: -1000;
  display: inline-block;

  img {
    width: 132px;
    height: 132px;
    padding: 6px; // 利用padding的特性，挤出白边
    background-color: #fff; //设置白色背景色
    box-sizing: border-box;
  }
}
</style>
